<template>
	<div class="doctor-list">
		<el-card style="margin-top: 20px;" shadow="never">
			<el-row style="display: flex;justify-content: flex-end;">
				<el-col><i class="el-icon-tickets"></i><span>用户组列表</span></el-col>
				<el-col>
					<el-button  style="float: right;" type="success" size="small"  @click="updata('add')">
						新增
					</el-button>
				</el-col>
			</el-row>
		</el-card>


		<!-- 弹窗 -->
		<el-dialog :title="action=='add'?'添加用户组':'修改用户组'" :visible.sync="allocDialogVisible" width="60%"
		 :modal-append-to-body="modalAppendToBody">
			<el-form ref="form" :model="form" :rules="dataRule" label-width="100px">


				<el-form-item label="用户组名" prop="title">
					<el-input v-model="form.title" style="width: 50%;"></el-input>
				</el-form-item>


				<el-form-item label="是否启用">
					<el-switch v-model="form.groupStatus"></el-switch>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="onSubmit">确定</el-button>
					<el-button type="info"    @click="allocDialogVisible=false">取消</el-button>
				</el-form-item>
			</el-form>

		</el-dialog>

		<el-card style="margin: 20px 0;" shadow="never">
			<el-table :data="tableData" style="width: 100%;">
				<el-table-column label="id" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.groupId }}</span>
					</template>
				</el-table-column>
				<el-table-column label="用户组名" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.groupName }}</span>
					</template>
				</el-table-column>


				<el-table-column label="是否启用" align="center">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.groupStatus" @change="switchbtn(scope)">
						</el-switch>
					</template>
				</el-table-column>

				<el-table-column label="添加时间" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.createTime }}</span>
					</template>
				</el-table-column>


				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<p>
							<el-button size="mini" type="text" @click="trshowbtn(scope)">配置规则</el-button>
							<el-button size="mini" type="text" @click="updata('edit',scope.row.groupId)">修改</el-button>
                            <el-button size="mini" type="text" @click="del(scope)">删除</el-button>
						</p>

					</template>
				</el-table-column>
			</el-table>
		</el-card>



		<!-- 弹窗 -->
		<el-dialog title="配置用户组" :visible.sync="trshow" width="60%" :modal-append-to-body="modalAppendToBody">
			<div style="height: 400px; overflow-y: auto;">
				<el-form>
					<el-form-item label="用户组名">
						<el-input style="width:300px" :disabled="true" v-model="egroupName" :placeholder="egroupName"></el-input>
					</el-form-item>
					<!-- 用户组名：<input type="text" :value="egroupName" disabled> -->
					<!-- <div class="group" v-for="item in authorList"> -->
					<!-- <h1>{{item.groupName}}</h1> -->
					<el-tree :data="data" show-checkbox ref="tree" node-key="id" :default-checked-keys="treeChecked"
					 :default-expand-all="false" :props="defaultProps">
					</el-tree>
					<!-- </div> -->
				</el-form>
			</div>
			<p style="margin:20px auto 0;text-align: center;">
				<el-button type="primary" @click="treebtn">确定</el-button>
				<el-button type="info" @click="trshow=false">取消</el-button>
			</p>

		</el-dialog>

<!-- 
		<el-pagination :hide-on-single-page="hideonsinglepage" background :total="5" layout="prev, pager, next">
		</el-pagination> -->

	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					groupStatus: true,
					title: ''
				},
				tableData: [],
				itemData: {},
				action: "add",
				src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
				Switch: false,
				hideonsinglepage: false,
				allocDialogVisible: false,
				value: '66',
				allocRoleIds: '',
				modalAppendToBody: false,
				trshow: false,
				egroupId: "", //修改的组id
				egroupName: "", //修改的组名
				treeChecked: [], //节点选中状态
				data: [],
				defaultProps: {
					children: 'children',
					label: 'title'
				},
                dataRule: {
                    title: [
                        { required: true, message: '用户组名不能为空', trigger: 'blur' }
                    ]
                },
			}
		},
		methods: {
			onSubmit() {
				const that = this;
                that.$refs['form'].validate((valid) => {
                    if (valid) {
                        let query = {};
                        let apiUrl = "";
                        query.title = that.form.title;
                        query.status = that.form.groupStatus == true ? 1 : 0;
                        if (that.action == "add") {
                            apiUrl = "/admin/manage/group_add";
                        } else if (that.action == "edit") {
                            query.groupId = that.form.groupId;
                            apiUrl = "/admin/manage/group_edit"
                        }
                        that.api(apiUrl, query).then(res => {
                            if (res.code == 200) {
                                that.$message.success("操作成功!");
                                that.getGroup();
                                that.allocDialogVisible = false;
                            }else{
                                that.$message.error("操作失败!");
                            }
                        })
                    }
                })
			},
			// 点击配置用户组确定
			treebtn() {
				const that = this;
				// console.log(this.$refs.tree.getCheckedKeys());
				let query = {
					groupId: that.egroupId,
					rules: this.$refs.tree.getCheckedKeys().join(",")
				}
				that.api('/admin/manage/group_access', query).then(res => {
					if (res.code == 200) {
						that.trshow = false;
					}
				})
			},


			// 删除方法
			del(delOjb) {

				const that = this;
                that.$confirm('您确认要删除该条用户信息么?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.api('/admin/manage/group_del', {
                        groupId: delOjb.row.groupId
                    }).then(res => {
                        if (res.code == 200) {
                            that.$message.success("删除成功!");
                            that.tableData.splice(delOjb.$index, 1);
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });


			},
			// 按钮开关
			switchbtn(e) {
				const that = this;
				that.api('/admin/manage/group_state', {
					groupId: e.row.groupId
				}).then(res => {
					if (res.code == 200) {
						// that.getlist();
					}
				})
			},
			// 修改
			updata(ac, id) {
				const that = this;
				that.allocDialogVisible = true;
				this.action = ac;
				if (ac == 'edit') {
					let data = that.$utils.getOne(that.tableData, id, "groupId");
					that.form.groupId = id;
					that.form.title = data.groupName;
					that.form.groupStatus = data.groupStatus;
				} else if (ac == 'add') {
					that.form = {}
				}
			},
			// 配置规则
			trshowbtn(data) {
				const that = this;
				that.trshow = true;
				that.egroupId = data.row.groupId;
				that.egroupName = data.row.groupName;
				that.getAuthor(data.row.groupId);
			},
			//获取权限信息
			getAuthor(id) {

				/* 示例数据
					[
						{
							groupName:"组名",
							treeList:[
								{
									id:1,
									title:'节点名',
									name:"admin/Sys",
									notcheck:false,
									pid:0,
									sort:1,
									status:true,
									type:true,
									level:1,
									children:[
										{
											id:2,
											title:'节点名',
											name:"admin/Sys",
											notcheck:false,
											pid:1,
											sort:1,
											status:true,
											type:true,
											level:2
										}
									]
									
								}
							]
						},
						{
							groupName:"组名2",
							treeList:[
								{
									id:1,
									title:'节点名',
									name:"admin/Sys",
									notcheck:false,
									pid:0,
									sort:1,
									status:true,
									type:true,
									level:1,
									children:[
										{
											id:2,
											title:'节点名',
											name:"admin/Sys",
											notcheck:false,
											pid:1,
											sort:1,
											status:true,
											type:true,
											level:2
										}
									]
									
								}
							]
						}
					]
				*/

				const that = this;
				let query = {}
				if (id) {
					query = {
						groupId: id
					}
				}
				that.api("/admin/manage/rule_list", query).then(res => {
					if (res.code == 200) {
						// console.log(res.data);
						if (id && res.data !== null) {
							that.treeChecked = res.data.split(",");
						} else {
							that.data = that.toTreeData(res.data, 0);
						}

					}
				});
			},
			//递归树
			toTreeData(data, paid) {

				function tree(id) {
					let arr = []
					data.filter(item => {
						return item.pid === id;
					}).forEach(item => {
						arr.push({
							id: item.id,
							title: item.title,
							pid: id,
							name: item.name,
							notcheck: item.notcheck,
							sort: item.sort,
							status: item.status,
							type: item.type,
							level: item.level,
							children: tree(item.id)
						})
					})
					return arr
				}
				return tree(paid)
			},
			//获取分组列表
			getGroup() {
				const that = this;
				that.api("/admin/manage/group_list").then(res => {
					if (res.code == 200) {
						for (var i = 0; i < res.data.length; i++) {
							if (res.data[i].groupStatus == '1') {
								res.data[i].groupStatus = true
							} else {
								res.data[i].groupStatus = false
							}
						}
						that.tableData = res.data;
					}

				});
			}
		},
		created() {
			const that = this;
			that.getAuthor();
			that.getGroup();
		}
	}
</script>

<style lang="less" scoped>
	.btn-add {
		float: right;
	}
</style>
